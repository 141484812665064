import { cn } from '@trustblock/helpers/classname';
import React from 'react';
import type { FieldValues, Path } from 'react-hook-form';
import Icon from '../../Icon/Icon';
import type { InputProps } from '../input.types';

function InputText<T extends FieldValues>({
  placeholder,
  label,
  validationRules,
  register,
  fieldName,
  readOnly,
  icon,
  error,
  hasRemoveButton,
  onClickRemove,
  onFocus,
  defaultValue,
  hideErrorMessage,
  className,
  ...props
}: InputProps<T>) {
  const registerData = register?.(fieldName as Path<T>, {
    ...validationRules,
    setValueAs: validationRules?.setValueAs
      ? validationRules.setValueAs
      : (value: unknown) => {
          if ((typeof value === 'string' && value.length === 0) || Number.isNaN(value)) {
            return undefined;
          }
          return value;
        }
  });

  return (
    <div className={cn('flex flex-col gap-1', { 'cursor-not-allowed opacity-40': readOnly }, className)}>
      {label && (
        <label htmlFor={fieldName} className="block text-black70 text-input-label">
          {label}
        </label>
      )}
      <div
        className={cn(
          'flex h-9 min-w-[170px] items-center overflow-hidden rounded-md border border-white107 bg-white90 px-3 transition-all duration-3 ease-out',
          {
            'border-error': error,
            'focus-within:border-primary': !error,
            'pr-0': hasRemoveButton
          }
        )}
      >
        {icon && (
          <>
            <Icon name={icon} className="w-4 h-4 mr-2 stroke-black50 text-black50" />
            <div className="mr-2 h-[70%] w-px bg-white107 transition-all duration-3 ease-out" />
          </>
        )}
        <input
          id={fieldName}
          type="text"
          defaultValue={defaultValue}
          placeholder={placeholder}
          readOnly={readOnly}
          onFocus={onFocus}
          className="flex-grow w-full h-full font-medium text-left placeholder-black50 bg-transparent text-black100 text-body focus:outline-none"
          {...registerData}
          {...props}
        />
        {hasRemoveButton && (
          <>
            <div className="h-[70%] w-px bg-white107 transition-all duration-3 ease-out" />
            <button
              type="button"
              onClick={onClickRemove}
              className="flex items-center justify-center w-10 min-w-10 h-full cursor-pointer hover:bg-white100"
              disabled={readOnly}
            >
              <Icon name="X" className="w-4 h-4 text-black50" />
            </button>
          </>
        )}
      </div>
      {error && !hideErrorMessage && (
        <div data-testid="input-error" className="text-error text-label">
          {error}
        </div>
      )}
    </div>
  );
}

export default InputText;
